import * as React from "react"
import { graphql, Link } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PlayIcon from "../components/icons/playAlt"
import ForwardIcon from "../components/icons/forward"
import VolumeIcon from "../components/icons/volume"
import Play from "../components/icons/play"
import SwipeStream from "../components/shared/swipeStream"
import AdSlot from "../components/shared/adSlot"

import FBComments from "../components/shared/fbComments"

const Stream = ({ data }) => {
    if (!data) return null
    const document = data.prismicChannel.data
    const settings = data.prismicGeneralSettings.data

    const handleClick = () => {
        window.open(
            document.live_channel_desktop_link?.url,
            "newWin",
            "toolbar=0, location=0, directories=0, status=0, menubar=0, scrollbars=1, resizable=0, width=1200, height=665"
        )
    }
    
    return (
        <Layout spacedFooter={true}>
            <SEO
                title={`${document.name?.text}: guardare il live in streaming su internet`}
                description={`Guarda i tuoi canali preferiti su computer, tablet e smartphone. Tutta la TV italiana in diretta. Gratis, live e in streaming su internet: ${document.name?.text}`}
            />
            <div className="mx-auto px-6 pt-5 pb-20 max-w-3xl">
                {/* Live Channel Player Top Slot */}
                <AdSlot html={settings.live_channel_player_top_slot?.text} />

                <button
                    className="group relative flex flex-col items-center justify-center mt-4 mb-4 w-full h-60 sm:h-72 lg:h-96 bg-black appearance-none focus:outline-none"
                    onClick={handleClick}
                    title={`Regarder ${document.name?.text} en direct`}
                >
                    <Play className="w-20 sm:w-24 md:w-32 text-white opacity-50 group-hover:opacity-75 transition" />
                    <div className="absolute left-0 bottom-0 flex items-center gap-4 py-4 px-6 text-white">
                        <PlayIcon width="20" />
                        <ForwardIcon width="20" />
                        <VolumeIcon width="20" />
                    </div>
                </button>

                {/* Live Channel Player After Player Slot */}
                <AdSlot
                    html={settings.live_channel_player_after_player_slot?.text}
                />

                <div className="mt-4 mb-6 sm:text-lg text-gray-600 text-center">
                    <p>
                        Se hai problemi per vedere la TV in diretta, consulta il
                        nostro{" "}
                        <Link
                            className="underline hover:text-primary"
                            to="/manuale"
                        >
                            Manuale di istruzioni
                        </Link>
                    </p>
                </div>

                <FBComments />
            </div>

            <SwipeStream channels={data.channels} />
        </Layout>
    )
}

export default withPreview(Stream)

export const query = graphql`
    query StreamQuery($uid: String!) {
        prismicGeneralSettings {
            data {
                live_channel_player_top_slot {
                    text
                }
                live_channel_player_after_player_slot {
                    text
                }
            }
        }
        prismicChannel(uid: { eq: $uid }) {
            data {
                live_channel_title {
                    raw
                }
                live_channel_content {
                    raw
                }
                live_channel_desktop_link {
                    url
                }
                name {
                    text
                }
            }
        }
        channels: allPrismicChannel {
            nodes {
                uid
                data {
                    name {
                        text
                    }
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 80
                                    quality: 100
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
